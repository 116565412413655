
import { defineComponent, ref } from 'vue';

import SocialMediaIconBox from '../../../components/Frontend/SocialMediaIconBox.vue';
import HeaderImageBoxGenerel from '../../../components/Frontend/HeaderImageBoxGenerel.vue';
import Footer from '../../../components/Frontend/Footer.vue';
import MultiUpload from '../../../components/util/MultiUpload.vue';

import { mdiChevronUp } from '@mdi/js';

import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  meta: {
    de: {
      title: 'Startup & Ventures | Styria Media Group',
      keywords: ['marketplaces', 'startup', 'venutres', 'styria media group'],
      description: 'Wir bleiben in Bewegung und innovieren. Wir investieren daher in Startups im Marktplatzsegment.  ',
      image: require('@/assets/img/og/brands.png'),
    },
    en: {
      title: 'Startup & Ventures | Styria Media Group',
      keywords: ['marketplaces', 'startup', 'venutres', 'styria media group'],
      description: 'We want to keep moving and innovate. We therefore invest into startups in the marketplaces segment.',
      image: require('@/assets/img/og/brands.png'),
    },
  },
  components: {
    SocialMediaIconBox,
    //HeaderImageBoxGenerel,
    Footer,
    MultiUpload,
  },
  setup() {
    const store = useStore();
    const i18n = useI18n();

    const path = `${store.getters.getHomeBase}datenschutz_${i18n.locale.value}.html`;

    const product = ref('');
    const company = ref('');
    const contactperson = ref('');
    const email = ref('');
    const tel = ref('');
    const text = ref('');

    const files = ref<Array<number>>([]);

    const error = ref('');
    const sent = ref(false);

    const onMultiUploadChange = (ids: Array<number>) => {
      files.value = ids;
    };

    const send = () => {
      error.value = '';

      if (!product.value && product.value == '') {
        error.value = i18n.t('General.formerror-product');
        return;
      }
      if (!company.value && company.value == '') {
        error.value = i18n.t('General.formerror-company');
        return;
      }
      if (!contactperson.value && contactperson.value == '') {
        error.value = i18n.t('General.formerror-contactperson');
        return;
      }
      if (!email.value && email.value == '') {
        error.value = i18n.t('General.formerror-email');
        return;
      }
      if (!tel.value && tel.value == '') {
        error.value = i18n.t('General.formerror-tel');
        return;
      }
      if (!text.value && text.value == '') {
        error.value = i18n.t('General.formerror-text');
        return;
      }

      if (error.value == '') {
        store
          .dispatch('sendMail', {
            type: 'startups',
            locale: i18n.locale.value,
            data: {
              product: product.value,
              company: company.value,
              contactperson: contactperson.value,
              email: email.value,
              tel: tel.value,
              text: text.value,
              files: files.value,
            },
          })
          .then(() => {
            sent.value = true;
          });
      }
      return;
    };
    return {
      sent,
      onMultiUploadChange,
      product,
      company,
      contactperson,
      email,
      tel,
      text,
      send,
      error,
      path,
    };
  },
});
