<style lang="scss">
#debug {
  display: none;
}
#frontend {
  .header-intro-generel-box {
    .title-box {
      span {
      }
    }
  }
  .digital-marketplaces-subpage {
    position: relative;
    .img-logo-overview {
      border-bottom: 1px solid #000;
      border-top: 1px solid #000;
      margin-top: 30px;
      img {
        max-width: 500px;
        margin: 50px auto 40px auto;
        display: block;
        @media (max-width: $width-sm) {
          max-width: 80%;
        }
      }
    }

    .intro-boxen {
      border: 0px solid red;
      .txt-box {
        h2 {
          margin-bottom: 20px;
        }
        padding: 0px 30px;
      }
      .img-box {
        height: 500px;
        background-size: cover;
        background-position: center center;
        max-width: 90%;
        margin: 20px auto;
      }
    }
    .start-outer {
      padding-bottom: 30px;
      padding-top: 30px;
      .img-box-start {
        width: 100%;
        height: 600px;
        background-size: cover;
        background-position: center center;
        @media (max-width: $width-lg) {
          margin: 0 auto 30px auto;
        }
      }
    }
    .mitarbeiter-marketplaces {
      //border:1px solid red;
      padding: 40px 20px;
      background: $blue;
      //border-radius:50px;
      position: relative;

      .txt-box {
        text-align: center;
        color: #fff;
        .name-person {
          letter-spacing: $letter-spacing-std;
          font-family: 'Helvetica Neue LT W05 85 Heavy';
          font-size: 200%;
        }
        .title-person {
          letter-spacing: $letter-spacing-std;
          font-size: 100%;
          padding: 15px 0;
        }
        .email-person {
          a {
            display: block;
            color: #fff;
            letter-spacing: 1px;
            font-size: 120%;
          }
        }
      }
      .img-person {
        border-radius: 50%;
        max-width: 90%;
        @media (max-width: $width-md) {
          display: block;
          margin: 20px auto;
          max-width: 70%;
        }
      }
    }
    .tab-outer-marketplaces {
      padding-top: 50px;
      padding-bottom: 50px;
      .tab-content {
        .tab-pane {
          padding-top: 0px;
        }
      }
    }

    .btn-formular {
      width: 100%;
      border: 0;
      background: #ccc;
      padding: 1em;
      text-transform: uppercase;
      transition: $transition-std;
      margin: 10px 0;
      font-size: 90%;
      letter-spacing: 2px;
      background: $blue;
      color: #fff;
      span {
        font-weight: 700;
        display: inline-block;
      }
      &:hover {
        opacity: 0.7;
      }
    }

    .brands-overview {
      margin-bottom: 30px;
      .col-lg-4 {
        border-right: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        &:nth-child(3) {
          border-right: 0;
        }
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
          border-bottom: 0;
        }
        &:nth-child(5) {
          border-right: 0;
        }
        @media (max-width: $width-lg) {
          &:nth-child(2),
          &:nth-child(4) {
            border-right: 0;
          }
          &:nth-child(3) {
            border-right: 1px solid #ccc;
          }
          &:nth-child(4) {
            border-bottom: 1px solid #ccc;
          }
          &:nth-child(5) {
            border-bottom: 1px solid #ccc;
          }
        }
        @media (max-width: $width-md) {
          border-right: 0 !important;
          border-bottom: 0 !important;
        }
      }
    }
    .brands-overview-v2 {
      margin-bottom: 30px;
      .col-lg-4 {
        border-right: 1px solid #ccc;
        //border-bottom:1px solid #ccc;
        &:nth-child(2) {
          border-right: 0;
        }

        @media (max-width: $width-lg) {
        }
        @media (max-width: $width-md) {
          border-right: 0 !important;
          border-bottom: 0 !important;
        }
      }
    }

    .brands-overview-inner {
      display: block;
      //border:1px solid red;
      height: 300px;
      cursor: pointer;
      transition: $transition-std;

      @media (max-width: $width-lg) {
        height: 250px;
      }
      @media (max-width: $width-md) {
        height: 200px;
      }
      img,
      .title-text {
        display: block;
        margin: 0px auto;
        max-width: 250px;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        transition: $transition-std;
      }
      .title-text {
        font-family: 'American Typewriter ITC W04 Md';
        font-size: 200%;
        text-align: center;
      }
      &:hover {
        transform: scale(0.85);

        img {
          opacity: 0.8;
        }
      }
    }

    .collapse-content {
      margin-top: 40px;
      .collapse-inner {
        background: #f1f1f1;
        padding: 25px;
      }
      .backto-overview {
        //border:1px solid blue;
        margin-bottom: 10px;
        display: block;
        position: relative;
        top: 0px;
        text-align: center;
        svg {
          width: 25px;
        }
      }
      h3 {
        text-transform: none !important;
        margin-bottom: 20px;
        margin-top: 10px;
      }
      .title-text-nachine-learning {
        font-family: 'American Typewriter ITC W04 Md';
        font-size: 200%;
        text-align: center;
      }
      img {
        max-width: 250px;
        display: block;
        margin: 15px auto;
      }
      .btn-contact {
        padding: 10px 0;

        a {
          display: block;
          background: $blue;
          padding: 10px 15px;
          color: #000;
          text-align: center;
          margin-right: 15px;
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
    .btn-std {
      margin-top: 30px;
    }
  }
}
</style>

<template>
  <!--<HeaderImageBoxGenerel :style="{ 'background-image': `url(${require('@/assets/img/marketplaces/marketplaces-startbild.jpg')})` }" headerImageText="Styria Digital Marketplaces" />-->
  <SocialMediaIconBox />
  <div class="digital-marketplaces-subpage">
    <div class="container-fluid  img-logo-overview">
      <div class="row padding-tb-s">
        <div class="col-lg-12">
          <img src="@/assets/img/marken/brands/styria-marketplaces.svg" alt="Marketplaces" class="img-fluid" />
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row header-section-box justify-content-center align-self-center ">
        <div class="col-lg-3 offset-lg-2 col-md-4 align-self-center">
          <div class="headline-section">{{ $t('marketplaces-startup-ventures.marketplaces') }}</div>
        </div>
        <div class="col-lg-6 col-md-8 justify-content-center align-self-center">
          <h1 v-html="$t('marketplaces-startup-ventures.startup-ventures-hl')"></h1>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <img src="@/assets/img/marketplaces/startup-ventures-v2.jpg" alt="Startup & Ventures" class="img-fluid shadow" />
        </div>
        <div class="col-lg-6 col-md-8 padding-tb-m">
          <h2 class="margin-tb-s">{{ $t('marketplaces-startup-ventures.startup-ventures-hl-v2') }}</h2>
          <p>{{ $t('marketplaces-startup-ventures.startup-ventures-txt-v2') }}</p>
        </div>
      </div>
      <div class="whitespace-line"></div>
      <div class="row justify-content-center">
        <div class="col-lg-8" v-if="!sent">
          <!-- -->
          <div class="row form-startups justify-content-end">
            <div class="col-lg-12 padding-tb-s">
              <h3>{{ $t('marketplaces-startup-ventures.form-hl') }}</h3>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <span class="label-input">{{ $t('General.form-produkt') }}</span> <input type="text" v-model="product" />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <span class="label-input">{{ $t('General.form-firmenname') }}</span> <input type="text" v-model="company" />
              </div>
            </div>
            <div class="col-lg-4">
              <div class="form-group">
                <span class="label-input">{{ $t('General.form-ansprechsperson') }}</span> <input type="text" v-model="contactperson" />
              </div>
            </div>
            <div class="col-lg-4">
              <div class="form-group">
                <span class="label-input">{{ $t('General.form-email') }}</span> <input type="text" v-model="email" />
              </div>
            </div>
            <div class="col-lg-4">
              <div class="form-group">
                <span class="label-input">{{ $t('General.form-telefonnr') }}</span> <input type="text" v-model="tel" />
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <span class="label-input">{{ $t('General.form-beschreibung') }}</span> <textarea v-model="text"></textarea>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <span class="label-input">{{ $t('marketplaces-startup-ventures.form-files') }}</span>
                <MultiUpload @changed="onMultiUploadChange" />
              </div>
            </div>

            <div class="col-lg-12">
              <a :href="path" target="_blank" v-html="$t('General.datenschutz-form-txt')" class="link-form-datenschutz"></a>
            </div>

            <div class="col-lg-4 margin-t-s">
              <button class="btn-formular" @click="send">{{ $t('marketplaces-startup-ventures.form-absenden') }}</button>
            </div>

            <div class="col-lg-4"></div>
            <div class="col-lg-4"></div>
          </div>
          <div class="row justify-content-center" v-if="error && error != ''">
            <div class="col-lg-12 col-md-10 text-center">
              {{ error }}
            </div>
          </div>
          <!-- -->
        </div>

        <div class="col-lg-8 text-center" v-if="sent">
          {{ $t('General.email-success') }}
        </div>
      </div>
      <div class="whitespace-line margin-t-m"></div>
      <div class="row margin-t-s">
        <div class="col-12">
          <div class="btn-std">
            <router-link :to="{ name: 'marketplaces-uebersicht' }"
              ><span class="txt">{{ $t('General.back-to-marketplaces-overview') }}</span> <span class="icon-img"><img src="@/assets/img/icon-arrow-right.svg" alt="Pfeil rechts" class="img-fluid"/></span
            ></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

import SocialMediaIconBox from '../../../components/Frontend/SocialMediaIconBox.vue';
import HeaderImageBoxGenerel from '../../../components/Frontend/HeaderImageBoxGenerel.vue';
import Footer from '../../../components/Frontend/Footer.vue';
import MultiUpload from '../../../components/util/MultiUpload.vue';

import { mdiChevronUp } from '@mdi/js';

import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  meta: {
    de: {
      title: 'Startup & Ventures | Styria Media Group',
      keywords: ['marketplaces', 'startup', 'venutres', 'styria media group'],
      description: 'Wir bleiben in Bewegung und innovieren. Wir investieren daher in Startups im Marktplatzsegment.  ',
      image: require('@/assets/img/og/brands.png'),
    },
    en: {
      title: 'Startup & Ventures | Styria Media Group',
      keywords: ['marketplaces', 'startup', 'venutres', 'styria media group'],
      description: 'We want to keep moving and innovate. We therefore invest into startups in the marketplaces segment.',
      image: require('@/assets/img/og/brands.png'),
    },
  },
  components: {
    SocialMediaIconBox,
    //HeaderImageBoxGenerel,
    Footer,
    MultiUpload,
  },
  setup() {
    const store = useStore();
    const i18n = useI18n();

    const path = `${store.getters.getHomeBase}datenschutz_${i18n.locale.value}.html`;

    const product = ref('');
    const company = ref('');
    const contactperson = ref('');
    const email = ref('');
    const tel = ref('');
    const text = ref('');

    const files = ref<Array<number>>([]);

    const error = ref('');
    const sent = ref(false);

    const onMultiUploadChange = (ids: Array<number>) => {
      files.value = ids;
    };

    const send = () => {
      error.value = '';

      if (!product.value && product.value == '') {
        error.value = i18n.t('General.formerror-product');
        return;
      }
      if (!company.value && company.value == '') {
        error.value = i18n.t('General.formerror-company');
        return;
      }
      if (!contactperson.value && contactperson.value == '') {
        error.value = i18n.t('General.formerror-contactperson');
        return;
      }
      if (!email.value && email.value == '') {
        error.value = i18n.t('General.formerror-email');
        return;
      }
      if (!tel.value && tel.value == '') {
        error.value = i18n.t('General.formerror-tel');
        return;
      }
      if (!text.value && text.value == '') {
        error.value = i18n.t('General.formerror-text');
        return;
      }

      if (error.value == '') {
        store
          .dispatch('sendMail', {
            type: 'startups',
            locale: i18n.locale.value,
            data: {
              product: product.value,
              company: company.value,
              contactperson: contactperson.value,
              email: email.value,
              tel: tel.value,
              text: text.value,
              files: files.value,
            },
          })
          .then(() => {
            sent.value = true;
          });
      }
      return;
    };
    return {
      sent,
      onMultiUploadChange,
      product,
      company,
      contactperson,
      email,
      tel,
      text,
      send,
      error,
      path,
    };
  },
});
</script>
